import React from "react"
import { Link, graphql } from "gatsby"
import cx from "classnames"
import marked from "marked"
import Container from "components/Container"
import Layout from "components/Layout"
import Title from "components/Title"
import TitleBlock from "components/Blocks/TitleBlock"
import Img from "gatsby-image/withIEPolyfill"
import altStyles from "../templates/WhatWeDo.module.css"
import cardStyles from "./Blog.module.css"
import styles from "./BlogPost.module.css"
import * as Icons from "components/Icons"
import HubspotForm from "components/HubspotForm"
import { Helmet } from "react-helmet"

const icons = {
  linkedin: Icons.Linkedin,
  twitter: Icons.Twitter,
  facebook: Icons.Facebook,
  github: Icons.Github,
}

// eslint-disable-next-line
export const BlogPostPage = ({ data, socials, html, headerImage, relatedPosts, ...props }) => (
  <Layout {...props} {...data}>
    <Helmet>
      <style type="text/css">
        {`
        pre {
          overflow-x: auto;
        }
      `}
      </style>
      {new Date(data.date).toISOString() > new Date().toISOString() ? (<meta name='robots' content='noindex' />) : null}
    </Helmet>
    <Container className={styles.post}>
      <div className={styles.breadcrumbs}>
        <p><Link to="/">Home</Link> / <Link to="/blog">Blog</Link> / {data.title}</p>
      </div>
      <Title className={`${altStyles.title} ${altStyles.titlesmall}`}>{data.title}</Title>
      {(data.author || {}).photo ? (
        <div className={styles.author}>
          <div className={styles.authorphoto}>
            {data.author.photo.extension === "svg" ? (
              <img alt={data.author.name} title={data.author.name} src={data.author.photo.publicURL} loading="lazy" width="79" height="79"/>
            ) : data.author.photo.childImageSharp ? (
              <Img
                loading="lazy"
                objectFit="contain"
                alt={data.author.name}
                title={data.author.name}
                fluid={data.author.photo.childImageSharp.fluid}
              />
            ) : null}
          </div>
          <div className={styles.authorfooter}>
            <span>{data.author.name} <span className={styles.authorSeparator}>•</span> </span>
            <time dateTime={data.date}>
              {new Date(data.date).toLocaleDateString("en-US", {
                month: "short",
                day: "numeric",
                year: "numeric",
              })}
            </time>
          </div>
        </div>
      ) : null}
      {data.image ? (
        <div className={styles.featuredImage}>
          <img
            loading="lazy"
            alt={data.title}
            title={data.title}
            src={data.image.childImageSharp.fluid.src}
          />
      </div>
      ) : null}
      <div className={styles.html} dangerouslySetInnerHTML={{ __html: html }} />
      <div className={styles.postActions}>
        <div className={styles.tags}>
          <h2>All Tags</h2>
          {(data.tags || []).filter(Boolean).map((tag) => (
            <Link to={`/blog/categories/${tag.replace(/\s/g, "-").toLowerCase()}`} key={tag}>
              {tag}
            </Link>
          ))}
        </div>
        <div>
          <h2>Share the Blog</h2>
          <div className={cx(styles.wrap, styles.socials)}>
            {Object.keys(socials).map((social) => (
              <a key={social} href={socials[social]} target="_blank" rel="noopener noreferrer">
                {icons[social]}
              </a>
            ))}
          </div>
        </div>
      </div>
    </Container>
    {relatedPosts.nodes.length > 0 ? (
      <Container className={styles.relatedPosts}>
        <TitleBlock isLarger>Related Posts</TitleBlock>
        <div>
          <Container className={`${cardStyles.posts} ${styles.noPaddingTop}`}>
            {(relatedPosts.nodes || []).filter(article => article.frontmatter.title !== data.title).map((node, i) => (
              node.frontmatter.title !== data.title && i < 3 ? (
              <article className={cardStyles.post} key={i}>
                {node.frontmatter.image ? (
                  <div className={cardStyles.postimage}>
                    <Img
                      objectFit="cover"
                      alt={node.frontmatter.title}
                      title={node.frontmatter.title}
                      fluid={node.frontmatter.image.childImageSharp.fluid}
                    />
                  </div>
                ) : null}
                <div className={cardStyles.info}>
                  <h2><Link to={`/post/${node.frontmatter.url}`}>{node.frontmatter.title}</Link></h2>
                  <div className={cardStyles.posttags}>
                    {(node.frontmatter.tags || []).filter(Boolean).map((tag) => (
                      <Link key={tag} to={`/blog/categories/${tag.replace(/\s/g, "-").toLowerCase()}`}>
                        {tag}
                      </Link>
                    ))}
                  </div>
                  {node.frontmatter.description ? (
                    <div
                      className={cardStyles.desc}
                      dangerouslySetInnerHTML={{ __html: marked(node.frontmatter.description.split(" ").splice(0,30).join(" ") + '...') }}
                    />
                  ) : null}
                  <time dateTime={node.frontmatter.date}>
                    {new Date(node.frontmatter.date).toLocaleDateString("en-US", {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                    })}
                  </time>
                </div>
              </article>
              ) : null
            ))}
          </Container>
        </div>
      </Container>
    ) : null}
    <div className={altStyles.grayBackground} id="contact-form">
      <Container>
      <div className={altStyles.titleContainer}>
          <TitleBlock isLarger><span className={altStyles.preTitle}>Contact Us</span>Learn more about how OpsGuru can help you with cloud adoption through our cloud services.</TitleBlock>
        </div>
        <HubspotForm id="b586e1c3-2aa2-4cce-815e-a8fb546726bb" portalId="6237410" />
      </Container>
    </div>
  </Layout>
  
)

// eslint-disable-next-line
export const BlogPostPageWrapper = (props) => (
  <BlogPostPage
    {...props}
    socials={props.data.socials.frontmatter.socials}
    html={props.data.markdownRemark.html}
    headerImage={props.data.headerImage}
    data={props.data.markdownRemark.frontmatter}
    relatedPosts={props.data.relatedPosts}
  />
)

export default BlogPostPageWrapper

export const pageQuery = graphql`
  query BlogPostPage($id: String, $categories: [String]) {
    headerImage: file(relativePath: { eq: "blog-post-intro.png" }) {
      childImageSharp {
        fluid(maxWidth: 3643) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    relatedPosts: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { 
        frontmatter: {templateKey: { eq: "blog" }, tags: {in: $categories } },
        isFuture: { eq: false }
      }
      limit: 10
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          url
          title
          description
          tags
          date
          image {
            childImageSharp {
              fluid(maxWidth: 480, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
    socials: markdownRemark(frontmatter: { templateKey: { eq: "main" } }) {
      frontmatter {
        socials {
          linkedin
          twitter
          facebook
          github
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        seo {
          title
          description
          canonical
          image {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100 ) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
        }
        title
        date
        tags
        author {
          name
          photo {
            extension
            publicURL
            childImageSharp {
              fluid(maxWidth: 480, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        image {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`
